import logo from './logo.svg';
import './App.css';
import Header from './components/header/header'
import Main from './components/main/main'

function App() {
  return (
    <>
    <Header/>
    <Main/>
    </>
  );
}

export default App;
